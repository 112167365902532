import React from 'react'
import Modal from 'react-modal'

import { Button, IconButton, IconColor, IconName } from 'Components'
import {
  LowWeightWarningShort,
  OverweightWarning,
  UnderweightWarning,
  UnderweightWarningKeepWeight
} from 'Components/WeightWarnings/WeightWarnings'
import { ProgramTypes } from 'Pages/AnamnesisPage/types'
import { Calculator } from 'Utils'
import { modalZIndexPriority } from 'Utils/zIndexPriority'

const WeightWarningContent: React.FC<{ height?: number | null; weight?: number; programType?: ProgramTypes }> = ({
  height = null,
  weight = null,
  programType
}) => {
  if (programType === ProgramTypes.KEEP_WEIGHT && Calculator.isUserUnderweight(weight, height)) {
    return <UnderweightWarning />
  }

  if (programType === ProgramTypes.LOSE_WEIGHT && Calculator.isUserUnderweight(weight, height)) {
    return <UnderweightWarningKeepWeight />
  }

  if (Calculator.isUserOverweight(weight, height)) {
    return <OverweightWarning />
  }

  return <LowWeightWarningShort />
}

const WeightWarningModal: React.FC<{
  isOpen: boolean
  closeModal: () => void
}> = ({ isOpen, closeModal, ...props }) => {
  return (
    <Modal style={modalZIndexPriority} isOpen={isOpen} onRequestClose={closeModal} ariaHideApp={false}>
      <div className="modal is-small">
        <IconButton name={IconName.close} color={IconColor.GRAY_1} onClick={closeModal} />
        <WeightWarningContent {...props} />

        <div className="row col-12 mt-1 justify-content-center">
          <div className="col-6">
            <Button onClick={closeModal} theme="small">
              Weiter
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WeightWarningModal

import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { useWatch } from 'react-hook-form'

import { StepHeading } from '../StepHeading'

import { EnergyLevelLabel, EnergyPlanCard, LabelMapping } from './EnergyPlanCard'

import { RenderStepType } from 'Components'
import {
  LowWeightWarning,
  OverweightWarning,
  UnderweightWarningSuspension
} from 'Components/WeightWarnings/WeightWarnings'
import { getAge } from 'Config'
import { FetchEnergyRequirementsPayload } from 'Models/EnergyRequirements'
import { ProgramTypes } from 'Pages/AnamnesisPage/types'
import { energyRequirementsSelector, fetchEnergyRequirementsAction } from 'ReduxStore/energyRequirements'
import { useAppDispatch, useAppSelector } from 'ReduxStore/hooks'
import { keyCloakUserSelector } from 'ReduxStore/keyCloakUser'
import { Calculator } from 'Utils'

const EnergyPlanStep: React.FC<RenderStepType> = ({ setValue, control, formValues }) => {
  const isMounted = useRef(false)
  const dispatch = useAppDispatch()
  const { attributes } = useAppSelector(keyCloakUserSelector)
  const energyRequirements = useAppSelector(energyRequirementsSelector)
  const [isEnergyRequirementsFetched, setIsEnergyRequirementsFetched] = useState(false)
  const chosenEnergyLevelName = useWatch({ name: 'energyLevelName', control })

  const payload: FetchEnergyRequirementsPayload = {
    age: getAge(formValues?.profile.birthday),
    gender: attributes.gender,
    height: formValues?.profile.height,
    overweightForTwoYears: formValues?.experience.overweightForTwoYears,
    parentsOverweight: formValues?.experience.parentsOverweight,
    previousFailureCount: formValues?.experience.previousFailureCount,
    professionalMotionLevel: formValues?.activity.professionalMotionLevel,
    weight: formValues?.profile.currentWeight,
    weightToLose: formValues?.profile.weightToLose,
    workingHours: formValues?.activity.workingHours
  }
  useEffect(() => {
    dispatch(fetchEnergyRequirementsAction(payload))
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      return setIsEnergyRequirementsFetched(true)
    }
    isMounted.current = true
  }, [energyRequirements])

  useEffect(() => {
    if (
      formValues?.programType.activeProgramType === ProgramTypes.KEEP_WEIGHT ||
      Calculator.isUserLowWeight(payload.weight, payload.height)
    ) {
      return
    }
    const chosenEnergyPlan = energyRequirements?.energyPlans.find(
      (energyPlan) => energyPlan.level.name === chosenEnergyLevelName
    )

    if (chosenEnergyPlan) {
      setValue('goalDate', dayjs().add(chosenEnergyPlan.days, 'day').toDate())
    }
  }, [energyRequirements, chosenEnergyLevelName])

  if (Calculator.isUserUnderweight(payload.weight, payload.height)) {
    return (
      <div className="infopanel my-2">
        <UnderweightWarningSuspension />
      </div>
    )
  }

  if (
    formValues?.programType.activeProgramType === ProgramTypes.KEEP_WEIGHT ||
    Calculator.isUserLowWeight(payload.weight, payload.height)
  ) {
    return (
      <>
        <StepHeading title="Ihr Ziel" infotext="Ihr Kalorienbedarf um Ihr aktuelles Gewicht zu halten." />
        <span>Gewicht: </span>
        <span>{Math.round(formValues?.profile.currentWeight)} kg</span>
        <fieldset
          style={{ border: '2px solid #32B6EF' }}
          className="infopanel my-2 row g-0 justify-content-center align-items-baseline"
        >
          <EnergyLevelLabel text={'Ihr Kalorienbedarf'} textColor={'text-blue-1'} />
          {energyRequirements && <span>{Math.round(energyRequirements.activeMetabolicRate)} kcal</span>}
        </fieldset>
        {formValues?.programType.activeProgramType === ProgramTypes.LOSE_WEIGHT &&
          Calculator.isUserLowWeight(payload.weight, payload.height) &&
          !Calculator.isUserUnderweight(payload.weight, payload.height) && (
            <div className="infopanel my-2">
              <LowWeightWarning />
            </div>
          )}

        {Calculator.isUserOverweight(payload.weight, payload.height) && (
          <div className="infopanel my-2">
            <OverweightWarning />
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <StepHeading title="Ihr Ziel" infotext="Wie schnell wollen Sie Ihr Wunschgewicht erreichen?" />
      <div className="row mb-2">
        <div>
          <p>Zielgewicht:</p>
          <p>Aktueller Kalorienbedarf:</p>
        </div>
        <div>
          <p>{Math.round(formValues?.profile.currentWeight - formValues?.profile.weightToLose)} kg</p>
          <p>{Math.round(energyRequirements?.activeMetabolicRate || 0)} kcal pro Tag</p>
        </div>
      </div>
      <div className="mb-5">
        {isEnergyRequirementsFetched &&
          energyRequirements?.energyPlans.map((energyPlan, index) => (
            <EnergyPlanCard
              key={index}
              energyPlan={energyPlan}
              onClickCard={() => setValue('energyLevelName', energyPlan.level.name)}
              cardHighlight={
                energyPlan.level.name === chosenEnergyLevelName ? { border: '2px solid #32B6EF' } : { border: '0' }
              }
              label={
                <EnergyLevelLabel
                  text={LabelMapping[energyPlan.level.name].text}
                  textColor={LabelMapping[energyPlan.level.name].textColor}
                />
              }
            />
          ))}
      </div>
      {Calculator.isUserOverweight(payload.weight, payload.height) && (
        <div className="infopanel my-2">
          <OverweightWarning />
        </div>
      )}
    </>
  )
}

export default EnergyPlanStep
export * from './config'

import React from 'react'

export const OverweightWarning: React.FC = () => (
  <>
    <h2>Ihr BMI liegt im Bereich des Übergewichts</h2>
    <p>
      Gemäss Ihren Angaben liegt Ihr BMI derzeit über 40. Das bedeutet nach der Klassifizierung der
      Weltgesundheitsorganisation (WHO) Adipositas Grad 3. Ab diesem BMI ist es wichtig, sich im Abnehmprozess begleiten
      zu lassen und eine geeignete Therapie zu finden. eBalance sollte in diesem Fall nur als begleitende Massnahme
      genutzt werden.
    </p>
    <p>
      Haben Sie bereits mit Ihrem Arzt oder Ihrer Ärztin diesbezüglich Kontakt aufgenommen? Diese haben auch die
      Möglichkeit, Verordnungen für Ernährungstherapien usw. auszustellen. Gern bieten wir auch an, für Ihren Arzt oder
      die zuständige Ernährungsberatung Auszüge aus Ihrem Profil bereitzustellen, falls dies gewünscht ist. Diese
      Auszüge umfassen beispielsweise das Ernährungstagebuch oder auch den Gewichtsverlauf. Wenden Sie sich bei Bedarf
      jederzeit an unseren Support unter <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>.
    </p>
  </>
)

export const UnderweightWarning: React.FC = () => (
  <>
    <h2>Ihr BMI liegt im Bereich des Untergewichts</h2>
    <p>
      Gemäss Ihren Angaben befindet sich Ihr BMI im Bereich des Untergewichts. Untergewicht ist aus gesundheitlicher
      Sicht nicht erstrebenswert, muss aber nicht in jedem Fall Grund zur Sorge sein, da es verschiedene Ursachen haben
      kann. Wir möchten Ihnen nahelegen, sich für eine Abklärung an Ihren Arzt / Ihre Ärztin zu wenden. eBalance richtet
      sich vorwiegend an übergewichtige Erwachsene bzw. auch an Personen, die im gesunden Rahmen des Normalgewichts ihr
      Wohlfühlgewicht erreichen möchten. Wenden Sie sich bitte, bei weiteren Fragen an den Support unter{' '}
      <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>, dort können Sie auch eine Ernährungsberatung anfragen.
    </p>
  </>
)

export const UnderweightWarningKeepWeight: React.FC = () => (
  <>
    <h2>Ihr BMI liegt im Bereich des Untergewichts</h2>
    <p>
      Gemäss Ihren Angaben befindet sich Ihr BMI im Bereich des Untergewichts. Untergewicht ist aus gesundheitlicher
      Sicht nicht erstrebenswert, muss aber nicht in jedem Fall Grund zur Sorge sein, da es verschiedene Ursachen haben
      kann. Daher haben wir Ihnen das Ziel “Gewicht halten” zugewiesen. Wir möchten Ihnen nahelegen, sich für eine
      Abklärung an Ihren Arzt / Ihre Ärztin zu wenden. eBalance richtet sich vorwiegend an übergewichtige Erwachsene
      bzw. auch an Personen, die im gesunden Rahmen des Normalgewichts ihr Wohlfühlgewicht erreichen möchten. Wenden Sie
      sich bitte, bei weiteren Fragen an den Support unter <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>, dort
      können Sie auch eine Ernährungsberatung anfragen.
    </p>
  </>
)

export const UnderweightWarningSuspension: React.FC = () => (
  <>
    <h2>Ihr BMI liegt im Bereich des Untergewichts</h2>
    <p>
      Gemäss Ihren Angaben befindet sich Ihr BMI im Bereich des Untergewichts. Untergewicht ist aus gesundheitlicher
      Sicht nicht erstrebenswert, muss aber nicht in jedem Fall Grund zur Sorge sein, da es verschiedene Ursachen haben
      kann. Wir möchten Ihnen nahelegen, sich für eine Abklärung an Ihren Arzt / Ihre Ärztin zu wenden.
    </p>
    <p>
      eBalance richtet sich vorwiegend an übergewichtige Erwachsene bzw. auch an Personen, die im gesunden Rahmen des
      Normalgewichts ihr Wohlfühlgewicht erreichen möchten. Aus diesem Grund müssen wir Sie zu Ihrem Schutz mit einem
      BMI von unter 18,5 von unserem Programm ausschliessen. Melden Sie sich bitte, auch wenn Sie sich ggf. vertippt
      haben, bei unserem Support unter <a href={'mailto:info@eBalance.ch'}>info@eBalance.ch</a>.
    </p>
  </>
)

export const LowWeightWarning: React.FC = () => (
  <>
    <p>Sie benötigen keine Gewichtsabnahme</p>
    <p>
      Gemäss Ihren Angaben befindet sich Ihr BMI im unteren Bereich des Normalgewichts. eBalance richtet sich vorwiegend
      an übergewichtige Erwachsene bzw. auch an Personen, die im gesunden Rahmen Ihr Wohlfühlgewicht erreichen möchten.
      Eine Abnahme würde in Ihrem Fall nicht zur Förderung der Gesundheit beitragen. Daher haben wir Ihnen das Ziel
      “Gewicht halten” zugewiesen. Vielleicht überlegen Sie sich Ihr Ziel nochmals. Ist es realistisch? Brauchen Sie
      wirklich eine Gewichtsreduktion, um sich fitter und wohler zu fühlen? Neben dem Gewicht gibt es weitere Faktoren,
      um sich wohler zu fühlen. Profitieren Sie hierfür von der Möglichkeit, Ihr Essverhalten unter die Lupe zu nehmen,
      wie auch von unseren Magazinartikeln und der Beratung. Haben Sie sich ggf. bei Ihren Eingaben vertippt, wenden Sie
      sich bitte an den Support unter <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>.
    </p>
  </>
)

export const LowWeightWarningShort: React.FC = () => (
  <>
    <h2>Sie benötigen keine Gewichtsabnahme</h2>
    <p>
      Gemäss Ihren Angaben befindet sich Ihr BMI im unteren Bereich des Normalgewichts. eBalance richtet sich vorwiegend
      an übergewichtige Erwachsene bzw. auch an Personen, die im gesunden Rahmen Ihr Wohlfühlgewicht erreichen möchten.
      Eine Abnahme würde in Ihrem Fall nicht zur Förderung der Gesundheit beitragen. Daher haben wir Ihnen das Ziel
      “Gewicht halten” zugewiesen. Neben dem Gewicht gibt es weitere Faktoren, um sich wohler zu fühlen. Profitieren Sie
      hierfür von der Möglichkeit, Ihr Essverhalten unter die Lupe zu nehmen, wie auch von unseren Magazinartikeln und
      der Beratung. Wenden Sie sich bitte, bei weiteren Fragen an den Support unter{' '}
      <a href="mailto:info@eBalance.ch">info@eBalance.ch</a>, dort können Sie auch eine Ernährungsberatung anfragen.
    </p>
  </>
)

export const constants = {
  /** Base URL to CDN images folder, will be replace with CDN version at build time */
  cdnBaseUrl: 'https://design-system.ebalance.dev/dist/img/',
  imageServiceFolders: {
    userPictures: 'avatars',
    userFoodPictures: 'user-foods',
    userRecipePictures: 'user-recipes'
  },
  recipeForm: {
    /** How many search results should be displayed to the user when searching for foods. */
    numberOfIngredientSearchHits: 20
  },
  /** https://en.wikipedia.org/wiki/N/A */
  notAvailable: 'N/A'
}
